$refcase-chart-title-height: 42px;
$refcase-chart-data-padding: 10px;

.refcase-chart-title {
    display: block;
    position: fixed;
    width: 100%;
    text-align: center;
    font-size: 20px;

    height: $refcase-chart-title-height;
    line-height: $refcase-chart-title-height;
}

.app-chart-data-container {
    padding: $refcase-chart-data-padding;
    width: calc(100% - 2 * #{$refcase-chart-data-padding});
    height: calc(100% - 2 * #{$refcase-chart-data-padding});
}

.app-chart-data-container > div.MuiPaper-root,
.app-chart-data-container > div.MuiPaper-root > div {
    height: 100%;
}


:export {
    refcaseChartTitleHeight: $refcase-chart-title-height;
}