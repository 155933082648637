@import 'webcore-ux/style/Colors';

// UI primary color
$app-primary-color: #00848f;

:export {
    appPrimaryColor: $app-primary-color;
}

// Override the font-size of any checkbox in a multi-select dropdown control
// in a grid header/filter row.  Make the font-size match the size of text
// in regular grid rows.
.MuiTableHead-root .de-multi-select-dropdown-root .wcux-nxt-checkbox span.MuiTypography-root {
    font-size: 14px;
}