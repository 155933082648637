.recharts-tooltip-wrapper {
    z-index: 1;
}

.recharts-tooltip-label, .recharts-tooltip-label * {
    font-size: 14px !important;
    font-weight: bold !important;
}

.recharts-tooltip-item-value, .recharts-tooltip-item-value * {
    font-weight: bold !important;
}

.recharts-cartesian-axis-ticks {
    font-size: 14px !important;
}

li.recharts-tooltip-item {
    font-size: 14px !important;
    padding-top: 4px !important;
    padding-bottom: 0px !important;
}

ul.recharts-default-legend li {
    font-size: 14px !important;
}

text.recharts-label {
    // Stroke and fill are both needed to completely affect the color of the labels
    // #666 matches ReCharts' label colors
    fill: #333333;
    stroke: #333333;
    stroke-width: 0.1;
}

////////////////////////////////////////////////////////////////////////////////
// ReCharts animations can be cool.  If isAnimationActive is true, then
// the animations can cause the chart's DOM elements to be destroyed and 
// recreated as the animation occurs.  When DOM elements go away, there's 
// no guarantee that we will get onMouseLeave events.  As a result, using
// React state to save the "hovered slice" of a pie, etc. to control
// mouse highlighting and interactions can cause unwanted side effects,
// like tooltips that don't go away, or hovered appearances that don't
// go back to normal when the mouse leaves.  That's why I've opted to 
// use CSS to highlight active pie slices here.
////////////////////////////////////////////////////////////////////////////////
.recharts-pie-sector > path {
    transition: opacity .1s;
}

.recharts-pie-sector > path:hover {
    opacity: 0.75;
}