@import 'common/Common.scss';

////////////////////////////////////////////////////////////////////////////////
// These are for containing the split container content
////////////////////////////////////////////////////////////////////////////////
.horizontal-split-container {
    height: 100%;
}
.horizontal-split-container div[data-testid="horizontal-panel-resizer-drag-bar"] {
    // The default z-index overlaid everything (1401!)
    z-index: 1 !important;
}

////////////////////////////////////////////////////////////////////////////////
// Override color of horizontal resizer to match NextGen colors
////////////////////////////////////////////////////////////////////////////////
// This is for the actual splitter bar
div[data-testid="horizontal-panel-resizer-drag-bar"][class*="makeStyles-dragging-"],
div[data-testid="horizontal-panel-resizer-drag-bar"]:hover:not(.wcux-drag-bar-disabled) {
    background-color: $app-primary-color !important;
    opacity: 64% !important;
}

// This is for the drag bar "button" border
div[data-testid="horizontal-panel-resizer-drag-bar"][class*="makeStyles-dragging-"] svg rect.border,
div[data-testid="horizontal-panel-resizer-drag-bar"]:hover:not(.wcux-drag-bar-disabled) svg rect.border {
    stroke: $app-primary-color !important;
}

// This is for the dots inside the drag bar "button"
div[data-testid="horizontal-panel-resizer-drag-bar"][class*="makeStyles-dragging-"] svg rect:not(:first-of-type),
div[data-testid="horizontal-panel-resizer-drag-bar"]:hover:not(.wcux-drag-bar-disabled) svg rect:not(:first-of-type) {
    fill: $app-primary-color !important;
}
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
