@import 'webcore-ux/style/Colors';

// App licensing drawer
div.app-licensing-drawer .wcux-slidepanel-title {
    font-size: 14px !important;
}

div.app-licensing-drawer > div.MuiPaper-root {
    width: 80%;
    font-size: 14px !important;
}

.app-licensing-content {
    display: flex;
    flex-flow: column;
    height: 100%;

    margin: 0;
    padding: 15px 15px 0 15px;
}

.app-licensing-heading {
    flex: 0 1 auto;
}

.app-licensing-licenses {
    flex: 1 1 auto;
    min-height: 0;

    margin: 0 0 15px 0;
    padding: 15px 15px 5px 15px;

    border: 1px solid $color-grey-235;
    border-radius: 3px;

    overflow-y: auto;
}

.app-licensing-content h3 {
    margin: 0 0 10px 0 !important;
    padding: 0 !important;
    font-weight: 700 !important;
}

.app-licensing-content h4 {
    margin: 0 0 10px 0 !important;
    padding: 0 !important;
    font-size: 1.0em;
    font-weight: 500 !important;
}

.app-licensing-content p {
    margin-bottom: 10px !important;
    white-space: normal;
}
