$edit-chart-content-padding: 20px;
$edit-chart-appbar-height: 56px;
$wcux-input-height: 100px;
$wcux-label-height: 22px;
$edit-chart-paper-adjustment: $edit-chart-appbar-height + 2 * $edit-chart-content-padding + $wcux-input-height + $wcux-label-height;
// For now these are the same.  Adjust later if needed.
$add-forecast-paper-adjustment: $edit-chart-paper-adjustment;

.edit-chart-dialog {
    z-index: 1300 !important;
}

.edit-chart-dialog-content {
    padding: $edit-chart-content-padding;
}

.add-forecasts-dialog {
    // Ensure that the add-forecasts dialog is on top of the edit-chart dialog
    z-index: 1301 !important;
}

.add-forecasts-dialog button.wcux-nxt-notification-close-button {
    pointer-events: none;
    // Hide the close button, but don't remove it
    z-index: -1;
}

.add-forecasts-dialog-content {
    padding: $edit-chart-content-padding;
}

.app-selected-forecasts-paper {
    height: calc(100vh - #{$edit-chart-paper-adjustment});
}

.delete-chart-confirmation {
    z-index: 1302;
}

span.forecast-detail-caption {
    display: inline-block;
    width: 105px;
}

span.forecast-detail-value {
    font-weight: bold;
}

.app-add-forecast-properties {
    margin-bottom: 30px;
    height: 70px;
    display: block;
}

.app-add-forecast-flex {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.app-add-forecast-types {
    padding-right: 15px;
    flex: 2;
}

.app-add-forecast-series {
    padding-right: 15px;
    flex: 1;
}

.app-add-forecast-axes {
    padding-right: 15px;
    flex: 1;
}

.app-add-forecast-names {
    flex: 3;
    // Don't let the series fields force this to grow
    min-width: 0;
}

.wcux-nxt-dropdown__menu {
    // The purpose of this is to ensure that the Webcore NextGen dropdown
    // appears over the grid in the selection dialogs
    z-index: 501 !important;
}

.forecast-action-icon {
    cursor: pointer;
}

.chart-title-popup-content {
    padding: 15px;
    max-width: 400px;
}

:export {
    editChartPaperAdjustment: $edit-chart-paper-adjustment;
    addForecastPaperAdjustment: $add-forecast-paper-adjustment;
}