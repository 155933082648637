@import 'webcore-ux/style/Colors';
@import 'common/Common.scss';

////////////////////////////////////////////////////////////////////////////////
// The styles in this document primarly override react-select styles to
// make it look consistent with WebCore UX.
//
// The following is the HTML structure with typical class names as a reference
// for what's affected by the styles below.
////////////////////////////////////////////////////////////////////////////////

// (A) Top-level element with our "de-" class applied
// <div class="de-multi-select-dropdown css-2b097c-container">
//   // (B) This element provides the visual border of the dropdown - border-radius, color, etc.
//   <div class=" css-1pahdxg-control">
//     // (C) This is the input area - where placeholders and selected items go
//     <div class=" css-1hwfws3">
//       // (D) Here's our multi-item text with our own class
//       <div class="multiple-selected-items-value">Item 1, Item 2, Item 3</div>
//       <div class="css-1g6gooi">
//         ...
//       </div>
//     </div>
//     // Parent container for all action buttons (clear, dropdown)
//     <div class=" css-1wy0on6">
//       // (H) container for an action button, e.g. clear all
//       <div aria-hidden="true" class=" css-1gtu0rj-indicatorContainer">
//         <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r">
//           <path
//             ...
//           </path>
//         </svg>
//       </div>
//       <span class=" css-1okebmr-indicatorSeparator"></span>
//       <div aria-hidden="true" class=" css-1gtu0rj-indicatorContainer">
//         <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r">
//           <path
//             ...
//           </path>
//         </svg>
//       </div>
//     </div>
//   </div>
//
//   // Now we're getting into the actual menu of options
//   <div class=" css-26l3qy-menu">
//     // Another container level
//     <div class=" css-11unzgr">
//       // Each of these is a container for a group
//       <div class=" css-1s9izoc">
//         // Each of these is the actual group menu item
//         <div class=" css-18ng2q5-group" id="react-select-2-group-0-heading">Index Prices</div>
//         <div>
//           // (I) Each of these is an option that can be selected
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-0" tabindex="-1">
//             // (J) Checkbox controls
//             <div class="wcux-checkbox multi-select-checkbox">
//               ...
//             </div>
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-1" tabindex="-1">
//           </div>
//           <div class=" css-9gakcf-option" id="react-select-2-option-0-2" tabindex="-1">
//           </div>
//         </div>
//       </div>
//       <div class=" css-1s9izoc">
//       </div>
//     </div>
//   </div>
// </div>

.de-multi-select-dropdown-root > label {
    display: block;
    line-height: 24px;
    margin-bottom: 4px;
    text-align: left;
}

// (A) Top-level element
.de-multi-select-dropdown {
    display: block;
}

// (B) Just inside dropdown
.de-multi-select-dropdown > div:first-of-type {
    border: 1px solid #889db5;
    border-radius: 2px;
    min-height: 40px;
    height: 42px;
    cursor: pointer;
}
.de-multi-select-dropdown > div:first-of-type:hover {
    border: 1px solid $app-primary-color;
}

// (B) Same as above, but when selected there is an additional span as the first child
.de-multi-select-dropdown > span[aria-live="polite"] + div {
    border-color: $app-primary-color;
    box-shadow: none;
}

// (C) Multivalue container / input area
.de-multi-select-dropdown > div:first-of-type > div:first-of-type {
    // Don't let content change size of input area
    max-height: 40px;
    overflow: hidden;
}

// (D) Multiple-selected items placeholder
.de-multi-select-dropdown div.multiple-selected-items-value {
    display: inline-block;
    // Prevent text from going to top of multi-select when overflow
    max-width: calc(100% - 6px);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $color-grey-31;
}

    // All button svg elements
.de-multi-select-dropdown svg {
    width: 16px;
    height: 16px;
}

// (H) Container for action buttons, e.g. clear all, dropdown
.de-multi-select-dropdown > div:first-of-type > div:nth-of-type(2) > div[class$="-indicatorContainer"] {
    padding-top: 8px;
    padding-right: 4px;
    padding-bottom: 8px;
    padding-left: 4px;
    color: $app-primary-color;
}

// (I) Menu options - selected and hovered
.de-multi-select-dropdown div[class$="-menu"] {
    z-index: 501;
}
.de-multi-select-dropdown div[class$="-menu"] div[class$="-option"] {
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    color: $color-grey-31;
}
.de-multi-select-dropdown div[class$="-menu"] div[class$="-option"]:hover {
    background-color: $color-grey-240;
}
.de-multi-select-dropdown div[class$="-menu"] svg {
    width: 24px;
    height: 24px;
}

// (J) Multi-select checkboxes
.de-multi-select-dropdown .multi-select-checkbox {
    // Without this, the check box's handling of events causes the 
    // dropdown to close even if we don't want it to
    pointer-events: none;
}

// Set font for the search text input field
.de-multi-select-dropdown input {
    font-family: "Open Sans", Helvetica;
}