@font-face {
    font-family: 'ABBvoice';
    src: local('ABBvoice_W_Rg'), url(./fonts/ABBvoice_W_Rg.woff) format('woff');
}

.app-authentication {
    position: absolute;
    top: 8px;
    right: 10px;
    white-space: nowrap;
}

.app-authentication > button.auth-button {
    height: 40px;

    border: none;
    background-color: rgba(242, 242, 242);
    color: rgba(51, 51, 51, 255);

    cursor: pointer;
}
    .app-authentication > button.auth-button > span {
        height: 40px;
        vertical-align: middle;

        font-family: "Open Sans", Helvetica;
        font-size: 16px;
    }

    .app-authentication > button.auth-button > svg {
        vertical-align: middle;
    }

body {
    margin: 0;
    padding: 0;
    min-width: 640px;
    min-height: 500px;

    font-family: 'open sans', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: rgba(51, 51, 51, 255);
}

#root {
    height: calc(100vh);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
